import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import components from '@/components/ui';
import { i18n } from './translate';
import { Buffer } from 'buffer';
import { tracking } from '@/tracking';
import { register } from 'swiper/element/bundle';
import * as Sentry from '@sentry/vue';

window.Buffer = Buffer;
register()

const app = createApp(App, {});

components.forEach((component) => {
  app.component(component.name, component);
});

Sentry.init({
  app,
  dsn: "https://e298682dfeef4590651ee319b41e5d4f@o528718.ingest.us.sentry.io/4508637006069760",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(i18n);
app.use(router);
app.use(store);
app.mount('#app');

window.addEventListener('load', () => {
  setTimeout(() => {
    tracking.initAllAnalytics();
  }, 4000);
});
